<template>
  <main class="view view--week">
    <section class="header">
      <div class="header__left">
        <button v-on:click="prevWeek" class="btn btn-prev"></button>
      </div>

      <div class="header__center">
        <p class="title">
          {{ $t("Week") }} {{ this.from_date.isoWeek() }}
          {{ this.from_date.format("YYYY") }}
        </p>
        <p class="subtitle">
          {{ start_date().format("DD/MM") }} - {{ end_date().format("DD/MM") }}
        </p>
      </div>

      <div class="header__right">
        <button v-on:click="nextWeek" class="btn btn-next"></button>
      </div>
    </section>
    <div v-if="is_offline" class="alert alert-warning">
      {{
        $t(
          "You don't have an internet connection at the moment. Please reconnect to get the latest data."
        )
      }}
    </div>
    <div class="icon-grid">
      <div class="icon-grid__item" v-for="day in days" :key="day.id">
        <div v-if="show_enddate >= day.moment">
          <router-link
            :to="{ name: 'day', query: { date: day.id } }"
            class="icon"
            :class="iconClass(day)"
          >
            <div class="icon__ball">
              <div class="day">{{ day.moment.format("DD") }}</div>
              <div class="month">{{ day.moment.format("MMM") }}</div>

              <span v-if="day.total_expired > 0" class="badge">{{
                day.total_expired
              }}</span>
            </div>
            <div class="icon__text">{{ day.label }}</div>
          </router-link>
        </div>
        <div v-if="show_enddate < day.moment">
          <div class="icon icon--disabled">
            <div class="icon__ball">
              <div class="day">{{ day.moment.format("DD") }}</div>
              <div class="month">{{ day.moment.format("MMM") }}</div>
            </div>
            <div class="icon__text">{{ day.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "week",
  mounted: function () {
    var self = this;

    if (this.$pwa) {
      this.$pwa
        .getSettings()
        .then((data) => {
          self.settings = data;
          this.updateView();
        })
        .catch(() => {
          this.updateView();
        });
    }

    this.$config.checkOnline(function (online) {
      self.is_offline = !online;
    });
  },
  methods: {
    prevWeek: function () {
      this.from_date = this.from_date.subtract(1, "week");
      this.updateView();
    },
    nextWeek: function () {
      this.from_date = this.from_date.add(1, "week");
      this.updateView();
    },
    getDates: function (start, end) {
      let dte_days = this.$helper.datesBetween(start, end);
      let days = [];
      for (let i = 0; i < dte_days.length; i++) {
        let moment_day = this.$moment(dte_days[i]);
        let day = {
          id: moment_day.format("YYYY-MM-DD"),
          moment: moment_day,
          total_slots: 0,
          total_timetrackings: 0,
          total_expired: 0,
          label: this.$helper.ucfirst(moment_day.format("dddd")),
        };
        days.push(day);
      }

      return days;
    },
    updateView: function () {
      this.$api
        .getTrackings(
          this.account.id,
          this.start_date().format("YYYY-MM-DD"),
          this.end_date().format("YYYY-MM-DD")
        )
        .then((timetrackings) => {
          var days = this.getDates(
            this.start_date().toDate(),
            this.end_date().toDate()
          );
          for (let index = 0; index < days.length; index++) {
            var day = days[index];
            var day_trackings = timetrackings[day.id];
            if (typeof day_trackings != "undefined") {
              for (let j = 0; j < day_trackings.length; j++) {
                let timetracking = day_trackings[j];
                if (timetracking.date === day.id) {
                  if (
                    timetracking.tracking == null &&
                    (day.moment <
                      this.$moment(this.$moment().format("YYYY-MM-DD")) ||
                      (day.id == this.$moment().format("YYYY-MM-DD") &&
                        this.$moment(timetracking.end, "HH:mm:ss") <
                          this.$moment()))
                  ) {
                    day.total_expired++;
                  }
                }
              }
            }

            days[index] = day;
          }

          this.days = days;
          this.$forceUpdate();
        })
        .catch(() => {
          this.days = this.getDates(
            this.start_date().toDate(),
            this.end_date().toDate()
          );
          this.$forceUpdate();
        });
    },
    start_date: function () {
      return this.$moment(this.from_date).startOf("isoWeek");
    },
    end_date: function () {
      return this.$moment(this.from_date).endOf("isoWeek");
    },
    iconClass: function (day) {
      return {
        "icon--danger": day.total_expired > 0,
        "icon--success":
          day.total_slots > 0 &&
          day.total_slots == day.total_timetrackings &&
          day.moment < this.$moment(this.$moment().format("YYYY-MM-DD")),
        "icon--current": this.$moment().format("YYYY-MM-DD") == day.id,
        "icon--disabled": day.moment.day() === 6 || day.moment.day() === 0,
      };
    },
  },
  computed: {
    show_enddate: function () {
      return this.$moment().add(this.settings.days_to_see_in_the_future, "day");
    },
  },
  data: function () {
    return {
      settings: [],
      account: this.$config.get("account"),
      from_date: this.$route.query.date
        ? this.$moment(this.$route.query.date)
        : this.$moment(),
      days: [],
      is_offline: false,
    };
  },
};
</script>
